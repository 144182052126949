import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div`
  border-radius: 50%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
  border: 2px solid
    ${props => (props.isHover ? '#FF043A' : 'rgba(255,255,255,0.5)')};
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  span {
    position: absolute;
    height: 2px;
    width: 60%;
    background: ${props =>
      props.isHover ? '#FF043A' : 'rgba(255,255,255,0.5)'};
    border-radius: 2px;
    left: 20%;
    top: 45%;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    transform: rotate(${props => (props.isOpen ? '0deg' : '90deg')});
  }
`

const NavIcon = ({isOpen, isHover}) => (
  <IconWrapper isHover={isHover} isOpen={isOpen}>
    <span />
    <span />
  </IconWrapper>
)

export default NavIcon
