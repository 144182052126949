/* eslint-disable react/no-danger */
import React, {Component} from 'react'
import styled from 'styled-components'
import ExpanerIcon from '../ExpanerIcon'

const QuestionWrapper = styled.div`
  h3 {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 0;
    transition: all 300ms ease-in-out;
    display: grid;
    grid-template-columns: 1fr 40px;
    &:hover {
      color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }
  }
  .content {
    padding: 0;
    overflow: hidden;
    max-height: ${props => (props.isOpen ? '600px' : '0')};
    transition: all 300ms ease-in-out;
    p,
    ul,
    ol {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 30px;
      font-size: 18px;
    }
    li {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`

class QuestionSingle extends Component {
  state = {
    isOpen: false,
    isHover: false,
  }

  hoverFaqHandler = () => {
    const {isHover} = this.state
    this.setState({isHover: !isHover})
  }

  openFaqHandler = () => {
    const {isOpen} = this.state
    this.setState({isOpen: !isOpen})
  }

  render() {
    const {isOpen, isHover} = this.state
    const {question} = this.props
    return (
      <QuestionWrapper isOpen={isOpen}>
        <h3
          onClick={this.openFaqHandler}
          onMouseOver={this.hoverFaqHandler}
          onMouseOut={this.hoverFaqHandler}
        >
          {question.title} <ExpanerIcon isOpen={isOpen} isHover={isHover} />
        </h3>
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: question.content}}
        />
      </QuestionWrapper>
    )
  }
}
export default QuestionSingle
