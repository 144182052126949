/* eslint-disable react/no-danger */
import React, {Component} from 'react'
import styled from 'styled-components'
import {Inner} from '../styles'
import QuestionSingle from './QuestionSingle'

const FAQWrapper = styled.div`
  background: ${props => props.theme.grey1};
  padding: 60px 0;
  margin: 60px 0 0 0;
`

class FAQs extends Component {
  state = {
    questions: [],
  }

  componentDidMount() {
    const {questions} = this.props
    if (questions) {
      this.setState({questions})
    }
  }

  render() {
    const {questions} = this.state
    return (
      <FAQWrapper>
        <Inner>
          {questions.map(question => (
            <QuestionSingle key={question.node.id} question={question.node} />
          ))}
        </Inner>
      </FAQWrapper>
    )
  }
}
export default FAQs
